<template>
  <div class="container-fluid rounded bg-white py-4">
    <div class="sub-modal">
      <div class="d-flex">
        <span class="h2 mr-auto text-secondary">
          <!-- Nouvelle Initiative --></span
        >

        <div class="row mb-3">
          <div class="col-12">
            <modal-actions
              v-if="$route.params.fromFdr"
              @close="
                $router.push({
                  name: 'show-fdr',
                  params: {
                    id: FDR.id,
                    slug: FDR.slug,
                    fromInitiative: true,
                  },
                })
              "
            />
            <modal-actions v-else @close="$router.go(-1)" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-flex flex-wrap justify-content-between">
          <span class="h2 mr-auto text-secondary">Nouvelle initiative</span>
          <div>
            <b-button
              type="submit"
              @click="saveItem"
              pill
              :disabled="submitingForm"
            >
              <b-spinner
                small
                v-if="submitingForm"
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
          </div>
        </div>
      </div>

      <div id="add-page" class="d-flex mt-3" style="gap: 5px">
        <div class="m-0 justify-content-between shdow col-md-4">
          <div
            class="
              col-12
              d-flex
              flex-wrap
              align-items-center
              justify-content-between
              p-0
            "
          >
            <p class="h4 text-secondary my-auto mr-auto">
              Informations générales
            </p>

            <!-- <b-button variant="secondary" type="submit" size="sm" pill class="mt-2"
              >Confirmer</b-button
            > -->
          </div>
          <div class="col-12 p-0">
            <hr class="mt-1 mb-3 bg-secondary" />
          </div>

          <div class="row p-0 m-0">
            <div class="col-12 p-0">
              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Libellé:</label>
                </div>
                <div class="col-md-8">
                  <b-form-input
                    placeholder="Libellé"
                    v-model="newInitiative.libelle"
                    :class="{
                      'is-invalid':
                        $v.newInitiative.libelle.$error &&
                        $v.newInitiative.libelle.$dirty,
                    }"
                  ></b-form-input>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newInitiative.libelle.$dirty"
                  >
                    {{
                      !$v.newInitiative.libelle.required
                        ? "Champ obligatoire"
                        : !$v.newInitiative.libelle.minLength
                        ? `Le champ doit contenir au moins ${$v.newInitiative.libelle.$params.minLength.min} caractères.`
                        : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Date de création:</label>
                </div>
                <div class="col-md-8">
                  <date-picker
                    :value="new Date()"
                    class="col-12 p-0 font-weight-normal"
                    format="DD/MM/YYYY"
                    disabled
                  />
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Responsabe:</label>
                </div>
                <div class="col-md-8">
                  <div
                    class="font-weight-normal p-0 form-control"
                    style="padding: 0 !important; height: fit-content"
                    :class="{
                      'is-invalid':
                        $v.newInitiative.responsable.$error &&
                        $v.newInitiative.responsable.$dirty,
                    }"
                  >
                    <v-select
                      class="bg-transparent p-0"
                      type="text"
                      placeholder="Responsabe"
                      required
                      label="text"
                      v-model="newInitiative.responsable"
                      :options="responsables"
                      :reduce="(responsable) => responsable.value"
                      :style="{
                        borderColor: '#dc3545',
                      }"
                    />
                  </div>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newInitiative.responsable.$dirty"
                  >
                    {{
                      !$v.newInitiative.responsable.required
                        ? "Champ obligatoire"
                        : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Statut:</label>
                </div>
                <div class="col-md-8">
                  <div
                    class="font-weight-normal p-0 form-control"
                    style="padding: 0 !important; height: fit-content"
                    :class="{
                      'is-invalid':
                        $v.newInitiative.status.$error &&
                        $v.newInitiative.status.$dirty,
                    }"
                  >
                    <v-select
                      class="bg-transparent"
                      v-model="newInitiative.status"
                      :options="statusInitiative"
                      placeholder="Statut"
                      :class="{
                        'is-invalid':
                          $v.newInitiative.status.$error &&
                          $v.newInitiative.status.$dirty,
                      }"
                    />
                  </div>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newInitiative.status.$dirty"
                  >
                    {{
                      !$v.newInitiative.status.required
                        ? "Champ obligatoire"
                        : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <!-- {{ FDR.budget || "" }}
                {{ fdrBudgetDisponible }} -->
                <!-- {{ FDR }} -->
                <div class="col-md-4">
                  <label class="font-weight-bold">FdR:</label>
                </div>

                <div class="col-md-8">
                  <div
                    class="font-weight-normal p-0 form-control"
                    style="padding: 0 !important; height: fit-content"
                    :class="{
                      'is-invalid':
                        $v.newInitiative.feuilleRoute.$error &&
                        $v.newInitiative.feuilleRoute.$dirty,
                    }"
                  >
                    <v-select
                      class="bg-transparent"
                      type="text"
                      :options="fdrList"
                      label="text"
                      :disabled="$route.params.fromFdr"
                      :reduce="(fdr) => fdr.value"
                      placeholder="FdR"
                      v-model="newInitiative.feuilleRoute"
                      :class="{
                        'is-invalid':
                          $v.newInitiative.feuilleRoute.$error &&
                          $v.newInitiative.feuilleRoute.$dirty,
                      }"
                      @input="getFdrCouvertures()"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                  </div>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newInitiative.feuilleRoute.$dirty"
                  >
                    {{
                      !$v.newInitiative.feuilleRoute.required
                        ? "Champ obligatoire"
                        : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Patrimoine:</label>
                </div>
                <div class="col-md-8">
                  <div
                    class="font-weight-normal p-0 form-control"
                    style="padding: 0 !important; height: fit-content"
                  >
                    <!-- :class="{
                      'is-invalid':
                        $v.newInitiative.patrimoine.$error &&
                        $v.newInitiative.patrimoine.$dirty,
                    }" -->
                    <v-select
                      class="bg-transparent"
                      label="text"
                      :reduce="(patrimoine) => patrimoine.value"
                      :options="patrimoineList"
                      v-model="newInitiative.patrimoine"
                      placeholder="Patrimoine"
                      disabled
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                  </div>
                  <!-- :class="{
                        'is-invalid':
                          $v.newInitiative.patrimoine.$error &&
                          $v.newInitiative.patrimoine.$dirty,
                      }" -->
                  <!-- ERROR MESSAGES -->
                  <!-- <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newInitiative.patrimoine.$dirty"
                  >
                    {{
                      !$v.newInitiative.patrimoine.required
                        ? "champ obligatoire"
                        : ""
                    }}
                  </span> -->
                  <!--  -->
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Type :</label>
                </div>
                <div class="col-md-8">
                  <div
                    class="font-weight-normal p-0 form-control"
                    style="padding: 0 !important; height: fit-content"
                    :class="{
                      'is-invalid':
                        $v.newInitiative.type.$error &&
                        $v.newInitiative.type.$dirty,
                    }"
                  >
                    <v-select
                      class="bg-transparent"
                      type="text"
                      :options="initiativetypes"
                      placeholder="Type"
                      v-model="newInitiative.type"
                      :class="{
                        'is-invalid':
                          $v.newInitiative.type.$error &&
                          $v.newInitiative.type.$dirty,
                      }"
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                  </div>
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newInitiative.type.$dirty"
                  >
                    {{
                      !$v.newInitiative.type.required ? "Champ obligatoire" : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Budget:</label>
                </div>
                <div class="col-md-8">
                  <CurrencyInput
                    placeholder="Budget"
                    v-model="newInitiative.budget"
                    :value="newInitiative.budget"
                    class="font-weight-normal"
                    :class="{
                      'is-invalid':
                        $v.newInitiative.budget.$error &&
                        $v.newInitiative.budget.$dirty,
                    }"
                  />

                  <!-- ERROR MESSAGES -->

                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newInitiative.budget.$dirty"
                  >
                    {{
                      !$v.newInitiative.budget.required
                        ? "Champ obligatoire"
                        : !$v.newInitiative.budget.minValue
                        ? `Le budget doit étre positif.`
                        : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Description:</label>
                </div>
                <div class="col-md-8">
                  <b-form-textarea
                    type="text"
                    placeholder="Description"
                    v-model="newInitiative.description"
                  ></b-form-textarea>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="m-0 justify-content-between shdow col-md-4">
          <div
            class="
              col-12
              d-flex
              flex-wrap
              align-items-center
              justify-content-between
              p-0
            "
          >
            <p class="h4 text-secondary my-auto mr-auto">Avancement</p>
          </div>
          <div class="col-12 p-0">
            <hr class="mt-1 mb-3 bg-secondary" />
          </div>

          <div class="row p-0 m-0">
            <div class="col-12 p-0">
              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Date de début:</label>
                </div>
                <div class="col-md-8">
                  <date-picker
                    :value="new Date()"
                    class="col-12 p-0 font-weight-normal"
                    format="DD/MM/YYYY"
                    v-model="newInitiative.startedAt"
                    :input-attr="{
                      class: {
                        'is-invalid':
                          $v.newInitiative.startedAt.$error &&
                          $v.newInitiative.startedAt.$dirty,
                        ' form-control': true,
                      },
                    }"
                    placeholder="Date de début"
                  />
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newInitiative.startedAt.$dirty"
                  >
                    {{
                      !$v.newInitiative.startedAt.required
                        ? "Champ obligatoire"
                        : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>

              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Date de fin:</label>
                </div>
                <div class="col-md-8">
                  <date-picker
                    placeholder="Date de fin"
                    :value="new Date()"
                    class="col-12 p-0 font-weight-normal"
                    format="DD/MM/YYYY"
                    v-model="newInitiative.endingAt"
                    :input-attr="{
                      class: {
                        'is-invalid':
                          $v.newInitiative.endingAt.$error &&
                          $v.newInitiative.endingAt.$dirty,
                        ' form-control': true,
                      },
                    }"
                  />
                  <!-- ERROR MESSAGES -->
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newInitiative.endingAt.$dirty"
                  >
                    {{
                      !$v.newInitiative.endingAt.required
                        ? "Champ obligatoire"
                        : $v.newInitiative.endingAt.required &&
                          $v.newInitiative.endingAt.$error
                        ? "La date de fin ne doit pas être antérieur à la date de début"
                        : ""
                    }}
                  </span>
                  <!--  -->
                </div>
              </div>
              <div class="row my-3 align-items-center">
                <div class="col-md-4">
                  <label class="font-weight-bold">Phase:</label>
                </div>
                <div class="col-md-8">
                  <div
                    class="font-weight-normal p-0 form-control"
                    style="padding: 0 !important; height: fit-content"
                  >
                    <v-select
                      class="bg-transparent"
                      type="text"
                      :options="[]"
                      placeholder="Type initiative"
                      :value="'Non démarré'"
                      disabled
                    >
                      <template #no-options> Liste vide </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="m-0 justify-content-between shdow col-md-4">
          <div
            class="
              col-12
              d-flex
              flex-wrap
              align-items-center
              justify-content-between
              p-0
            "
          >
            <p class="h4 text-secondary my-auto mr-auto">Couvertures</p>
          </div>
          <div class="col-12 p-0">
            <hr class="mt-1 mb-3 bg-secondary" />
          </div>
          <div class="row p-0 m-0">
            <div class="col-12 p-0">
              <div id="couverture" class="col-12 p-0 my-3 align-items-center">
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                >
                  <v-select
                    multiple
                    label="country"
                    v-model="couvertures"
                    :options="fdrCouvertures"
                    placeholder="Choisissez les couvertures"
                    class="col-12 p-0 font-weight-normal bg-transparent"
                  >
                    <template #option="{ country, countryCode }">
                      <span v-if="countryCode">
                        <country-flag
                          :country="countryCode"
                          size="small"
                          v-show="countryCode"
                        />
                        {{ country }}</span
                      >
                    </template>
                    <template #selected-option="{ country, countryCode }">
                      <div
                        class="d-flex"
                        style="display: flex; align-items: baseline"
                      >
                        <span v-if="country">
                          <country-flag
                            v-if="countryCode"
                            :country="countryCode"
                            size="small"
                          />
                          {{ country }}</span
                        >
                      </div>
                    </template>

                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
              </div>

              <div class="row m-0 p-0">
                <div
                  style="height: 50px; position: relative; overflow: visible"
                  class="shdow m-1"
                  v-for="(covert, index) in couvertures"
                  :key="index"
                >
                  <p>
                    <country-flag
                      :country="covert.countryCode"
                      size="small"
                    />{{ covert.country || "" }}
                  </p>

                  <div style="position: absolute; top: -5px; right: -5px">
                    <span style="cursor: pointer"
                      ><i
                        @click="deleteCouverture(index)"
                        class="mdi mdi-close-circle text-danger"
                      ></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- // {{ couvertures }} -->
        </div>
      </div>
      <hr />

      <b-tabs class="tab-solid tab-solid-primary">
        <b-tab
          title="Finance"
          v-if="isAdmin || isSuper || isManager || isPo"
          active
        >
          <div class="row justify-content-between p-0 m-0 flex-wrap">
            <!-- <div class="col-sm-6 col-12 mb-2 pl-sm-0"> -->
            <div class="p-0 col-12 mb-2">
              <div class="m-0 p-0 h-100 shdow">
                <div class="hdr py-2 text-white rounded mb-3">
                  Situation actuelle : {{ moment().format("DD/MM/YYYY") }}
                </div>
                <div class="p-4">
                  <div class="d-flex">
                    <p class="mt-2"><span class="mr-1"> Fichier : </span></p>
                    <FilePicker
                      disabled
                      class="font-weight-normal my-auto"
                      :isfinance="true"
                    />
                  </div>
                  <div @submit.prevent="addAjustement">
                    <div class="row m-0 p-0 align-items-center">
                      <span class="mr-2 col-3 p-0">Ajustement</span>

                      <b-form-input
                        placeholder="Ajuster"
                        class="col-5 mr-2 border border-gray"
                        size="sm"
                        type="number"
                        disabled
                        required
                      ></b-form-input>
                    </div>
                    <div class="row m-0 mt-2 p-0 align-items-center">
                      <span class="mr-2 col-3 p-0">Commentaire</span>
                      <b-form-input
                        placeholder="Commentaire"
                        class="col-5 mr-2 border border-gray"
                        size="sm"
                        required
                        disabled
                      ></b-form-input>
                    </div>
                    <div class="row m-0 mt-2 p-0 align-items-center">
                      <div class="d-flex p-0 col-8">
                        <b-button
                          disabled
                          size="sm"
                          type="submit"
                          class="bg-blue ml-auto"
                          >Réviser</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="false"
                  class="text-gray d-flex h2 text-center w-100"
                  style="height: 166px"
                >
                  <i
                    class="mdi my-auto ml-auto mdi-information-outline my-auto"
                  ></i>
                  <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
                </div>
              </div>
            </div>
            <div v-if="false" class="col-sm-6 col-12 mb-2 pr-sm-0">
              <div class="m-0 p-0 h-100 shdow">
                <div class="hdr py-2 text-white rounded mb-3">Suivi conso:</div>
                <div
                  class="text-gray d-flex h2 text-center w-100"
                  style="height: 166px"
                >
                  <i
                    class="mdi my-auto ml-auto mdi-information-outline my-auto"
                  ></i>
                  <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
                </div>
              </div>
            </div>
          </div>
          <div class="hdr py-2 text-white rounded-top mb-3">
            Historique des révisions
          </div>
          <b-table
            :items="[]"
            :fields="financeHistory.tableFields"
            responsive
            show-empty
          >
            <!-- FOR LOADING ANIMATIONS -->
            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-2"></b-spinner>
                <strong>Chargement...</strong>
              </div>
            </template>
            <!-- SHOW IF TABLE IS EMPTY -->
            <template #empty>
              <div class="text-center text-gray">Table vide</div>
            </template>
            <template v-slot:cell(responsable)="data">
              <div>
                <b-avatar
                  v-b-tooltip.hover="
                    data.value
                      ? data.value.firstname + ' ' + data.value.lastname
                      : '-'
                  "
                  variant="primary"
                  class="mr-1"
                >
                  <div
                    style="
                      width: 30px;
                      height: 30px;
                      border-radius: 15px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    {{
                      data.value
                        ? data.value.firstname[0] + "." + data.value.lastname[0]
                        : "-"
                    }}
                  </div>
                </b-avatar>
              </div>
            </template>

            <template #cell(tendance)="data">
              <div class="p-0 m-0 row">
                <i
                  style="font-size: 20px; color: red"
                  class="mdi mdi-menu-down"
                ></i>
                <p>{{ data.value }}</p>
              </div>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Référents">
          <div class="row p-0 m-0">
            <div class="col-12 d-flex justify-content-between">
              <span class="h4 text-secondary mr-auto my-auto"
                >Liste des référents
              </span>

              <CustomAddButton class="ml-auto" text="Ajouter rôle" disabled />
            </div>
            <div class="col-12">
              <hr class="mt-1 mb-3 bg-secondary" />
            </div>
            <div class="mx-auto">
              <div
                class="text-gray d-flex h2 text-center w-100"
                style="height: 166px"
              >
                <i
                  class="mdi my-auto ml-auto mdi-information-outline my-auto"
                ></i>
                <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Les enjeux stratégiques">
          <StrategicChallenge
            :disabled="true"
            :tableItems="[]"
            @cancel="(itemIndex) => removeStrategyChallengeInputs(itemIndex)"
          />
        </b-tab>
        <b-tab title="Gains">
          <Gains :tableItems="[]" disabled />
        </b-tab>
        <b-tab title="Impacts">
          <Impacts v-if="showImpact" :tableItems="[]" disabled />
          <TransformationPlan v-else />
        </b-tab>

        <b-tab title="Cadres d'entreprise">
          <CompanyFrame disabled :tableItems="[]" />
        </b-tab>
        <b-tab title="Planning">
          <div>
            <Planning :planning="[]" :disabled="true" :newTasks="[]" />
          </div>
        </b-tab>

        <b-tab title="Tâches et livrables">
          <task-deliverable
            @showDeliverables="showDeliverablesModal = !showDeliverablesModal"
            :taskDeliverable="[]"
            :disabled="true"
          ></task-deliverable>
        </b-tab>

        <b-tab title="Comités et décisions">
          <div>
            <CommitteeDecision
              :disabled="true"
              :tableItems="[]"
              @searchGuests="searchGuestsModal = true"
            />
          </div>
        </b-tab>
        <b-tab title="Imputations">
          <div class="d-flex">
            <Imputation
              :disabled="true"
              @showRessourses="showRessourses = true"
            />
          </div>
          <div class="d-flex justify-content-between">
            <div class="col-md-5 p-0">
              <div class="col-12 p-0">
                <span class="h4 text-secondary mr-auto my-auto"
                  >Budget consommé
                </span>

                <hr class="mt-1 mb-3 bg-secondary" />
              </div>
              <div
                class="text-gray d-flex h2 text-center w-100"
                style="height: 166px"
              >
                <i
                  class="mdi my-auto ml-auto mdi-information-outline my-auto"
                ></i>
                <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
              </div>
            </div>
            <div class="col-md-5 p-0">
              <div class="col-12 p-0">
                <span class="h4 text-secondary mr-auto my-auto"
                  >Imputations par Direction
                </span>

                <hr class="mt-1 mb-3 bg-secondary" />
              </div>
              <div
                class="text-gray d-flex h2 text-center w-100"
                style="height: 166px"
              >
                <i
                  class="mdi my-auto ml-auto mdi-information-outline my-auto"
                ></i>
                <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>

    <!-- Show deliverables modal -->
    <div v-if="showDeliverablesModal" to="modals-xyz-548">
      <modal @close="showDeliverablesModal = !showDeliverablesModal">
        <Deliverables />
      </modal>
    </div>

    <!-- Search guests -->
    <div to="modals-xyz-548" v-if="searchGuestsModal">
      <Modal @close="searchGuestsModal = !searchGuestsModal">
        <SearchGuests @close="searchGuestsModal = !searchGuestsModal" />
      </Modal>
    </div>

    <!-- Add Frame -->
    <div to="modals-xyz-548" v-if="addFrameModal">
      <Modal @close="addFrameModal = !addFrameModal">
        <add-frame @close="addFrameModal = !addFrameModal" />
      </Modal>
    </div>

    <!-- Search role -->
    <div to="modals-xyz-548" v-if="searchRoleModal">
      <Modal @close="searchRoleModal = !searchRoleModal">
        <search-role @close="searchRoleModal = !searchRoleModal" />
      </Modal>
    </div>
  </div>
</template>

<script>
// import DropdownSelection from "@/components/custom/DropdownSelection.vue";
import TaskDeliverable from "./components/TaskDeliverable.vue";
import Deliverables from "./components/Deliverables.vue";
import Modal from "@/components/custom/Modal.vue";
import StrategicChallenge from "./components/StrategicChallenge.vue";
import Gains from "./components/Gains.vue";
import Impacts from "./components/Impacts.vue";
import CompanyFrame from "./components/CompanyFrame.vue";
import Planning from "./components/Planning.vue";
import CommitteeDecision from "./components/CommitteeDecision.vue";
import SearchGuests from "./components/SearchGuests.vue";
import AddFrame from "./components/AddFrame.vue";
import searchRole from "./components/searchRole.vue";
import ModalActions from "@/components/custom/ModalActions.vue";
import TransformationPlan from "@/components/custom/TransformationPlan.vue";
// import VueSlideBar from "vue-slide-bar";
import Swal from "sweetalert2";
import moment from "moment";
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import FilePicker from "@/components/custom/FilePicker.vue";
import Imputation from "./components/Imputation";
import CustomAddButton from "@/components/custom/CustomAddButton";
import CountryFlag from "vue-country-flag";
import CurrencyInput from "@/components/custom/CurrencyInput";

export default {
  name: "AddInitiative",
  components: {
    CustomAddButton,
    // VueSlideBar,
    // DropdownSelection,
    TaskDeliverable,
    Deliverables,
    Modal,
    StrategicChallenge,
    Gains,
    Impacts,
    CompanyFrame,
    Planning,
    CommitteeDecision,
    SearchGuests,
    AddFrame,
    ModalActions,
    TransformationPlan,
    searchRole,
    FilePicker,
    Imputation,
    CountryFlag,
    CurrencyInput,
  },
  data: () => ({
    searchRoleModal: false,
    submitingForm: false,
    couvertures: [],
    newInitiative: {
      libelle: "",
      responsable: "",
      status: "",
      feuilleRoute: "",
      patrimoine: "",
      domaine: "",
      type: "",
      phase: "",
      description: "",
      avancement: 0,
      endingAt: "",
      startedAt: "",
      budget: 0,
      responsableFin: null,
    },

    financeHistory: {
      tableFields: [
        {
          key: "date_revision",
          label: "Date révision",
          sortable: true,
        },
        { key: "responsable", label: "Resp Fin", sortable: true },
        {
          key: "budget_fdr_initial",
          label: "Budget FDR initial",
          sortable: true,
        },
        {
          key: "montant_ajuste",
          label: "Montant ajusté",
          sortable: true,
        },
        {
          key: "budget_fdr_revise",
          label: "Budget FDR révisé",
          sortable: true,
        },
        {
          key: "commentaire",
          label: "Commentaire",
        },
        { key: "tendance", label: "Tendance", sortable: true },
        // { key: "actions", label: "", sortable: false },
      ],
    },
    addFrameModal: false,
    searchGuestsModal: false,
    showImpact: true,

    showDeliverablesModal: false,
  }),
  validations: {
    newInitiative: {
      libelle: {
        required,
        minLength: minLength(4),
      },
      responsable: {
        required,
      },
      status: {
        required,
      },
      feuilleRoute: {
        required,
      },
      // patrimoine: {
      //   required,
      // },
      type: {
        required,
      },
      avancement: 0,
      endingAt: {
        required,
        minValue(val) {
          return this.newInitiative.startedAt
            ? moment(val, "DD/MM/YYYY").diff(
                moment(this.newInitiative.startedAt, "DD/MM/YYYY")
              ) +
                1 >
                0
            : true;
        },
      },
      startedAt: {
        required,
      },
      budget: {
        required,
        minValue(val) {
          return parseFloat(val) > 0;
        },
      },
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  methods: {
    deleteCouverture(index) {
      this.couvertures.splice(index, 1);
      // id && this.deletedCouverture.push(id);
    },
    getFdrCouvertures() {
      window.console.log(
        this.FDRS.find((fdr) => fdr["@id"] === this.newInitiative.feuilleRoute)
      );
      this.newInitiative.feuilleRoute &&
        this.$store.dispatch(
          "fdr/fetchFdrCouvertures",
          this.newInitiative.feuilleRoute.substring(35)
        );
      if (this.newInitiative.feuilleRoute) {
        this.couvertures = [];
        this.newInitiative.patrimoine = this.PATRIMOINES.find((patrimoine) =>
          patrimoine.feuilles.find(
            (feuille) => feuille["@id"] === this.newInitiative.feuilleRoute
          )
        )["@id"];
      } else {
        this.newInitiative.patrimoine = "";
      }
      // FOR RESPONSABLE FIN
      this.newInitiative.feuilleRoute &&
        this.$store.dispatch(
          "fdr/fetchFdr",
          this.newInitiative.feuilleRoute.substring(35)
        );
    },
    saveItem() {
      window.console.log(this.responsableFin);
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (this.fdrBudgetDisponible > 0) {
          if (this.fdrBudgetDisponible >= this.newInitiative.budget) {
            this.submitingForm = true;
            this.$store
              .dispatch("initiative/createInitiative", {
                ...this.newInitiative,
                startedAt: moment(
                  this.newInitiative.startedAt,
                  "DD/MM/YYYY"
                ).format("DD/MM/YYYY"),
                endingAt: moment(
                  this.newInitiative.endingAt,
                  "DD/MM/YYYY"
                ).format("DD/MM/YYYY"),
                budget: this.newInitiative.budget.toString(),
              })
              .then(() => {
                this.$store.dispatch(
                  "financeInitiative/updateFinanceInitiative",
                  {
                    id: this.INITIATIVE.finance.id,
                    responsable: this.responsableFin
                      ? this.responsableFin
                      : this.newInitiative.responsable["@id"],
                  }
                );
              });
          } else {
            Swal.fire({
              title:
                "Le budget de l'initiative est supérieur au budget disponible",
              text: `Budget disponible : ${this.fdrBudgetDisponible}€`,
              type: "error",
            });
          }
        } else {
          Swal.fire({
            title:
              "Impossible de créer une initiative supérieure à l’écart budget de la FdR!",
            type: "error",
          });
        }
      }
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("cStatus/fetchAllStatusByType", "Initiative");
    this.$store.dispatch("fdr/fetchAllFdrs");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("cType/fetchInitiativeTypes");
    this.$store.dispatch("phase/fetchAllPhases");
    this.$store.dispatch("refPhase/fetchAllRefPhases");
    // REDIRECTION FROM FDR TO INITIATIVE
    if (this.$route.params.fromFdr && this.FDR["@id"]) {
      this.newInitiative.feuilleRoute = this.FDR["@id"];
      this.$store.dispatch("tabs/changeTab", 2);
    }
    if (this.newInitiative.feuilleRoute) {
      this.newInitiative.patrimoine = this.PATRIMOINES.find((patrimoine) =>
        patrimoine.feuilles.find(
          (feuille) => feuille["@id"] === this.newInitiative.feuilleRoute
        )
      )["@id"];
      this.$store.dispatch(
        "fdr/fetchFdrCouvertures",
        this.newInitiative.feuilleRoute.substring(35)
      );
    }
  },
  computed: {
    ...mapGetters("cStatus", ["STATUS_INITIATIVES"]), //get all status
    ...mapGetters("organisation", ["ORGANISATIONS"]), //get all organisations
    ...mapGetters("fdr", ["FDR", "FDRS", "COUVERTURES"]), //get all FDRs
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("cType", ["INITIATIVE_TYPES"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("phase", ["PHASES"]), //get all phases
    ...mapGetters("refPhase", ["REF_PHASES"]), //get all phases
    ...mapGetters("initiative", ["INITIATIVE"]),
    ...mapGetters(["isAdmin", "isManager", "isSuper", "isPo"]),

    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/users/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    statusInitiative() {
      return this.STATUS_INITIATIVES;
    },
    fdrList() {
      return this.FDRS.map((feuille) => ({
        value: feuille["@id"],
        text: feuille.name,
      }));
    },
    organisations() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: organisation["@id"],
        text: organisation.libelle,
      }));
    },
    patrimoineList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        value: patrimoine["@id"],
        text: patrimoine.name,
      }));
    },
    initiativetypes() {
      return this.INITIATIVE_TYPES;
    },
    phasesList() {
      return this.PHASES.map((phase) => ({
        ...phase,
        value: phase["@id"],
        text: phase.libelle,
      }));
    },
    refPhasesList() {
      return this.REF_PHASES.map((phase) => phase.name);
    },
    fdrCouvertures() {
      if (this.newInitiative.feuilleRoute) return this.COUVERTURES.couvertures;
      return [];
    },
    fdrBudgetDisponible() {
      if (this.newInitiative.feuilleRoute && this.FDR) {
        return (
          parseFloat(this.FDR.budget) -
          // parseFloat(
          // this.FDR.finance.ajustements.reduce(
          //   (total, ajustement) =>
          //     parseFloat(total) + parseFloat(ajustement.montant),
          //   0
          // )

          // this.FDR.finance.ajustements
          //   .map(({ montant }) => parseFloat(montant))
          //   .reduce((prev, curr) => prev + curr, 0)
          (this.FDR.initiatives
            ? this.FDR.initiatives
                .map(({ budget }) => parseFloat(budget))
                .reduce((prev, curr) => prev + curr, 0)
            : 0)
        );
        // )
      }
      return 0;
    },
  },
  mounted() {
    if (this.$route.params.fromFdr)
      this.newInitiative.feuilleRoute = this.FDR["@id"];
    if (this.$route.params.fromFdr) {
      this.$store.dispatch("tabs/changeTab", 2);
    }
  },

  watch: {
    INITIATIVE() {
      this.couvertures.map((couverture) => {
        this.$store.dispatch("couverture/createCouverture", {
          initiative: this.INITIATIVE["@id"],
          adresse: couverture.adresse,
          country: couverture.country,
          countryCode: couverture.countryCode,
          lat: couverture.lat,
          lng: couverture.lng,
        });
      });
      this.$store.dispatch("financeInitiative/updateFinanceInitiative", {
        id: this.INITIATIVE.finance.id,
        responsable: this.responsableFin
          ? this.responsableFin
          : this.newInitiative.responsable["@id"],
      });
      this.$store
        .dispatch("referent/createReferent", {
          initiative: this.INITIATIVE["@id"],
          user: this.INITIATIVE.responsable["@id"],
          role: "PO/CDP",
        })
        .then(() => {
          Swal.fire({
            title: "L'initiative est bien créée !",
            type: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              this.$router.push({
                name: "show-initiative",
                params: {
                  slug: this.INITIATIVE.slug || "abcd",
                  id: this.INITIATIVE.id,
                  fromCreation: true,
                },
              }); //redirect to the new task
            }
          });
        });
      this.submitingForm = false;
    },
    FDR() {
      if (this.$route.params.fromFdr && this.FDR["@id"])
        this.newInitiative.feuilleRoute = this.FDR["@id"];
      if (!this.$route.params.fromFdr && this.FDR["@id"]) {
        this.responsableFin = this.FDR.finance
          ? this.FDR.finance.responsable["@id"]
          : null;
      }
    },
    "newInitiative.feuilleRoute"() {
      if (this.newInitiative.feuilleRoute) {
        var fdr = this.FDRS.find(
          (fdr) => fdr["@id"] === this.newInitiative.feuilleRoute
        );
        this.$store.dispatch("fdr/fetchFdr", fdr.id).then(() => {
          window.console.log(this.FDR);
        });
      }
    },
  },
};
</script>
<style>
#couverture .vs__selected-options {
  display: none;
}
#couverture .vs__actions {
  margin-left: auto;
}
</style>

<style scoped>
.top_gantt_controls {
  width: 100%;
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  margin-bottom: 10px;
}

.header_sd_menu {
  height: 60px;
  background-color: white;
  width: 100%;
  border: 2px #f2edf3 solid;
}

.sd_menu_row {
  width: 100%;
  height: 38px;
}

.fl_1 {
  flex: 1;
}

.fl_2 {
  flex: 2;
}

.fl_3 {
  flex: 3;
}

.txt_desc {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.table_responsivo {
  overflow: scroll;
}

.popUpFather {
  position: relative;
}

@media (min-width: 991px) {
  .modal-xyz-548 {
    position: absolute;
    width: calc(100% - 331px) !important;
    height: 72% !important;
    top: 165px !important;
    right: 35px !important;
    z-index: 3000;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -ms-flex-pack: center;
  }
}

@media (max-width: 991px) {
  .modal-xyz-548 {
    width: 100%;
  }
}

.tr_clckble {
  cursor: pointer;
}

.tr_clckble:hover {
  background-color: rgba(139, 44, 235, 0.342);
}

.hdr {
  background-color: #072648;
  text-align: center;
}

.hdr > p {
  color: white;
}

.spn {
  color: #797979;
}
</style>
<style>
.vs__search {
  color: #cfcfcf;
}
/* .mx-datepicker {
  width: 100% !important;
} */
</style>
