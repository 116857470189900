<template>
  <div id="add-page" class="col-md-8 col-12 row m-0 p-0">
    <div class="col-12 p-0 mb-auto">
      <div class="col-12 d-flex p-0">
        <span class="h4 text-secondary mr-auto my-auto"
          >Total des imputations par phase
        </span>
      </div>
      <div class="col-12 p-0">
        <hr class="mt-1 mb-3 bg-secondary" />
      </div>
      <b-table
        responsive
        :fields="tableFields"
        :items="tableItems"
        show-empty
        bordered
        striped
        :busy="loading"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <!-- FOR LOADING ANIMATIONS -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>

        <template v-slot:cell(contributions)="data">
          <b-badge
            v-if="data.value.length"
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            pill
            @click="
              (selectedContributions = data.value),
                (showContribution = true),
                (taskName = data.item.task.libelle)
            "
            >{{ data.value.length }}</b-badge
          >
          <b-badge
            v-else
            style="
              cursor: pointer;
              background-color: #adb5bd;
              border-color: #adb5bd;
            "
            pill
            >{{ 0 }}</b-badge
          >
        </template>
        <template v-slot:cell(task)="data">
          <b-link
            v-if="!data.item.hasOwnProperty('editMode') || data.item.id"
            :to="{
              name: 'show-task',
              params: {
                id: data.value.id,
                slug: data.value.slug,
              },
            }"
            >{{ data.value.libelle }}</b-link
          >
          <div v-else-if="!data.item.id">
            <div
              class="font-weight-normal p-0 form-control"
              style="padding: 0 !important; height: fit-content"
              :class="{
                'is-invalid': $v.selectedTask.$error && $v.selectedTask.$dirty,
              }"
            >
              <v-select
                label="text"
                appendToBody
                style="min-width: max-content; background: transparent"
                :reduce="(task) => task.value"
                :options="tasksList"
                v-model="selectedTask"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </div>
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.selectedTask.$dirty"
            >
              {{ !$v.selectedTask.required ? "Champ obligatoire" : "" }}
            </span>
          </div>
        </template>
        <template v-slot:cell(phase)="data">
          <span v-if="!data.item.hasOwnProperty('editMode') || data.item.id">{{
            data.value
          }}</span>
          <div v-else-if="!data.item.id">
            <div
              class="font-weight-normal p-0 form-control"
              style="padding: 0 !important; height: fit-content"
              :class="{
                'is-invalid':
                  $v.selectedPhase.$error && $v.selectedPhase.$dirty,
              }"
            >
              <v-select
                label="text"
                appendToBody
                @input="selectedTask = null"
                v-model="selectedPhase"
                style="min-width: max-content; background: transparent"
                :reduce="(phase) => phase.value"
                :options="phasesList"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </div>
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.selectedPhase.$dirty"
            >
              {{ !$v.selectedPhase.required ? "Champ obligatoire" : "" }}
            </span>
          </div>
        </template>

        <template v-slot:cell(chargesEstime)="data">
          <span v-if="!data.item.hasOwnProperty('editMode')">{{
            data.value
          }}</span>
          <div v-else>
            <b-form-input
              :class="{
                'is-invalid':
                  $v.chargesEstime.$error && $v.chargesEstime.$dirty,
              }"
              type="number"
              min="0"
              v-model="chargesEstime"
            />
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.chargesEstime.$dirty"
            >
              {{ !$v.chargesEstime.required ? "Champ obligatoire" : "" }}
            </span>
          </div>
        </template>

        <template v-slot:cell(chargesRestantes)="data">
          <span v-if="!data.item.hasOwnProperty('editMode')">{{
            data.value
          }}</span>
          <div v-else>
            <b-form-input
              :class="{
                'is-invalid':
                  $v.chargesRestantes.$error && $v.chargesRestantes.$dirty,
              }"
              type="number"
              min="0"
              v-model="chargesRestantes"
            />

            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.chargesRestantes.$dirty"
            >
              {{ !$v.chargesRestantes.required ? "Champ obligatoire" : "" }}
            </span>
          </div>
        </template>
        <template #cell(actions)="data">
          <table-actions
            :actions="['delete']"
            :editMode="data.item.hasOwnProperty('editMode')"
            @confirmItem="confirmItem(data.item)"
            @cancelItem="cancelItem(data.item)"
            @editItem="editItem(data.item)"
            @deleteItem="deleteItem(data.item.id)"
          ></table-actions>
        </template>
        <!-- SHOW IF TABLE IS EMPTY -->
        <template #empty>
          <div class="text-center text-gray">Table vide</div>
        </template></b-table
      >
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
      class="mt-auto ml-auto"
    >
    </b-pagination>
    <!-- Ressources Modal -->
    <div to="modals-xyz-548" v-if="showContribution">
      <Modal @close="showContribution = !showContribution">
        <ContributionList
          :initiativeName="initiativeName"
          :taskName="taskName"
          :contributions="selectedContributions"
          @close="showContribution = !showContribution"
        />
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import CustomAddButton from "@/components/custom/CustomAddButton";
import TableActions from "@/components/custom/TableActions";
import Modal from "@/components/custom/Modal";
import ContributionList from "./ContributionList";
import Swal from "sweetalert2";
import { concat } from "lodash";
// import moment from "moment";
import { required } from "vuelidate/lib/validators"; //validation

export default {
  components: {
    //CustomAddButton,
    TableActions,
    ContributionList,
    Modal,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    initiativeName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sortBy: "name",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      showContribution: false,
      loading: true,
      tableItems: [],
      selectedContributions: [],
      selectedTask: null,
      taskName: "",
      selectedPhase: null,
      chargesRestantes: null,
      chargesEstime: null,
    };
  },
  validations: {
    selectedTask: {
      required,
    },
    selectedPhase: {
      required,
    },
    chargesEstime: {
      required,
    },
    chargesRestantes: {
      required,
    },
  },
  methods: {
    addItem: function () {
      if (
        !this.tableItems.some((item) => (item.editMode ? item.editMode : false))
      ) {
        this.tableItems.unshift({ editMode: true });
        this.selectedPhase = null;
        this.selectedTask = null;
        this.chargesRestantes = null;
        this.chargesEstime = null;
      }
    },
    confirmItem: function (item) {
      this.$v.$touch();
      window.console.log(!this.$v.$invalid);
      if (!this.$v.$invalid) {
        if (!item.id)
          this.$store
            .dispatch("imputation/createImputation", {
              chargesEstime: parseInt(this.chargesEstime),
              task: this.selectedTask,
              chargesRestantes: parseInt(this.chargesRestantes),
              collaborateur: "/api/users/17",
            })
            .then(() => {
              this.$store
                .dispatch(
                  "initiative/fetchInitiativeImputations",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire({
                    title: "L'imputation est bien créée !",
                    type: "success",
                    confirmButtonText: "OK",
                  });
                  this.selectedPhase = null;
                  this.selectedTask = null;
                  this.chargesRestantes = null;
                  this.chargesEstime = null;
                });
            });
        else
          this.$store
            .dispatch("imputation/updateImputation", {
              id: item.id,
              chargesEstime: parseInt(this.chargesEstime),
              chargesRestantes: parseInt(this.chargesRestantes),
            })
            .then(() => {
              // fetch data again
              this.$store
                .dispatch(
                  "initiative/fetchInitiativeImputations",
                  this.$route.params.id
                )
                .then(() => {
                  Swal.fire("L'imputation est mise à jour !", "", "success");
                  this.selectedPhase = null;
                  this.chargesRestantes = null;
                  this.chargesEstime = null;
                });
            });
      }
    },
    editItem: function (item) {
      this.$store
        .dispatch(
          "initiative/fetchInitiativeImputations",
          this.$route.params.id
        )
        .then(() => {
          this.chargesEstime = item.chargesEstime;
          this.chargesRestantes = item.chargesRestantes;
          this.selectedTask = item;
          this.selectedPhase = item;

          this.tableItems = this.tableItems.map((imputation) =>
            imputation.id === item.id
              ? {
                  ...imputation,
                  editMode: true,
                }
              : imputation
          );
        });
    },
    cancelItem: function (item) {
      if (
        this.selectedPhase === null &&
        item.chargesEstime === null &&
        item.chargesRestantes === null
      ) {
        this.$store.dispatch(
          "initiative/fetchInitiativeImputations",
          this.$route.params.id
        );
      } else {
        item.chargesEstime = null;
        this.selectedPhase = null;
        item.chargesRestantes = null;
      }
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("imputation/deleteImputation", id).then(() => {
            this.$store
              .dispatch(
                "initiative/fetchInitiativeImputations",
                this.$route.params.id
              )
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
  },
  created() {
    this.$route.params.id
      ? this.$store
          .dispatch(
            "initiative/fetchInitiativeImputations",
            this.$route.params.id
          )
          .then(() => {
            this.loading = false;
          })
      : (this.loading = false);
  },
  computed: {
    ...mapGetters("initiative", ["INITIATIVE", "TASKS", "IMPUTATIONS"]),
    ...mapGetters("imputation", ["IMPUTATION"]),

    phasesList() {
      return this.TASKS.phases.map((phase) => ({
        text: phase.libelle,
        value: phase["@id"],
      }));
    },

    tasksList() {
      return this.TASKS.phases.find(
        (phase) => phase["@id"] === this.selectedPhase
      )
        ? this.TASKS.phases
            .find((phase) => phase["@id"] === this.selectedPhase)
            .tasks.map((task) => ({
              text: task.libelle,
              value: task["@id"],
            }))
        : [];
    },
    rows() {
      return this.tableItems.length;
    },
    tableFields() {
      return [
        { key: "phase", label: "Phase" },
        { key: "task", label: "Tache" },
        { key: "contributions", label: "Nb ressources" },
        { key: "chargesEstime", label: "Charges estimées" },
        { key: "chargesRestantes", label: "Charges imputées" },
      ];
    },
  },
  watch: {
    IMPUTATIONS() {
      var imputations = [];
      window.console.log(this.IMPUTATIONS, "imputation");
      if (this.$route.params.id) {
        // check if this is the show page
        this.IMPUTATIONS.phases.map((phase) => {
          phase.tasks.map((task) => {
            task.imputations.map((imputation, index) => {
              let charge =
                parseFloat(imputation.semaine ? imputation.semaine.lundi : 0) +
                parseFloat(imputation.semaine ? imputation.semaine.mardi : 0) +
                parseFloat(
                  imputation.semaine ? imputation.semaine.mercredi : 0
                ) +
                parseFloat(imputation.semaine ? imputation.semaine.jeudi : 0) +
                parseFloat(
                  imputation.semaine ? imputation.semaine.vendredi : 0
                ) +
                parseFloat(imputation.semaine ? imputation.semaine.samedi : 0) +
                parseFloat(
                  imputation.semaine ? imputation.semaine.dimanche : 0
                ); // calcul de la charge

              imputations = concat(imputations, {
                ...imputation,
                task: {
                  libelle: task.libelle,
                  id: task.id,
                  slug: task.slug || "abcde",
                  etp: task.etp || 0,
                },
                contributions: task.contributions,
                phase: phase.libelle,
                // chargesEstime: task.etp || 0,
                // chargesRestantes: task.etp || 0,
                chargesEstime:
                  index === 0
                    ? task.etp || 0
                    : imputations[index - 1].chargesRestantes, // charge Estimee

                chargesRestantes:
                  index === 0
                    ? (index === 0
                        ? task.etp || 0
                        : imputations[index - 1].chargesRestantes) - charge
                    : imputations[index - 1].chargesRestantes - charge, // charge Restante
              });
            });
          });
        });
      }
      this.tableItems = imputations;
    },
  },
};
</script>
|'abcde'
