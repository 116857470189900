<template>
  <section class="tables">
    <div class="row" style="margin-top: -56px">
      <div class="col-10">
        <p class="h2 text-secondary">
          <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

          <span style="font-size: 18px" class="font-weight-light"
            >Initiative :
          </span>
          <span style="font-size: 22px">{{ initiativeName }}</span>
        </p>
      </div>
      <div class="col-10">
        <p class="h2 text-secondary">
          <i style="font-size: 18px" class="mdi mdi-file-tree"></i>

          <span style="font-size: 16px" class="font-weight-light"
            >Tâche :
          </span>
          <span style="font-size: 18px">{{ taskName }}</span>
        </p>
      </div>
      <div class="col-12">
        <h4 class="card-title mt-2 mb-1">Liste des contributers :</h4>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-12">
        <b-table
          id="table-list"
          responsive
          :items="contributions"
          :fields="tableFields"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          show-empty
          bordered
          striped
        >
          <!-- SHOW IF TABLE IS EMPTY -->
          <template #empty>
            <div class="text-center text-gray">Table vide</div>
          </template>
          <template #cell(user)="data">
            <TableResponsable
              :responsable="data.value"
              v-if="!data.item.hasOwnProperty('editMode')"
            />
          </template>

          <template #cell(commentaire)="data">
            <span v-html="data.value || '-'"></span>
          </template>
          <template #cell(nbrejours)="data">
            <span v-html="data.value || 0"></span>
          </template>

          <template #cell(status)="data">
            <!-- {{ $v.contributers.$each[0].status }} -->
            <span v-html="data.value || '-'"></span>
          </template>
          <template #cell(conso)="data">
            <b-progress
              max="100"
              show-progress
              style="background-color: #cacccd !important"
              height="15px"
            >
              <b-progress-bar
                :value="data.value"
                :label="`${data.value}%`"
                :style="`background: ${
                  data.value > 40 && data.value <= 75
                    ? '#ffde59'
                    : data.value > 75
                    ? '#d90429'
                    : '#38b000'
                }`"
              ></b-progress-bar>
            </b-progress>
          </template>

          <template #cell(avancement)="data">
            <b-progress
              max="100"
              show-progress
              style="background-color: #cacccd !important"
              height="15px"
            >
              <b-progress-bar
                :value="data.value"
                :label="`${data.value}%`"
                :style="`background-color: ${
                  data.value > 20 && data.value <= 70
                    ? '#f5f245'
                    : data.value > 70
                    ? '#23d92c'
                    : '#f5ba45'
                }`"
              ></b-progress-bar>
            </b-progress>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
        >
        </b-pagination>
      </div>
    </div>
  </section>
</template>
<script>
import Vue from "vue";
import SortedTablePlugin from "vue-sorted-table";
import TableResponsable from "@/components/custom/TableResponsable.vue";

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>',
});

export default {
  components: {
    TableResponsable,
  },
  props: {
    initiativeName: String,
    taskName: String,
    contributions: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      sortBy: "name",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      tableFields: [
        { key: "user", label: "Contributeurs", sortable: false },
        { key: "commentaire", label: "Commentaire", sortable: false },
        { key: "nbrejours", label: "ETP", sortable: false },
        { key: "status", label: "Statut", sortable: false },
        { key: "conso", label: "Conso", sortable: false },
        { key: "avancement", label: "Avancement", sortable: false },
      ],
      tableItems: [],
      fdrName: "",
      searchQuery: {
        libelle: "",
        fdr: "",
        status: "",
        responsable: "",
      },
    };
  },
  computed: {
    rows() {
      return this.contributions.length;
    },
  },
};
</script>
